import { Injectable } from '@angular/core';
import { TeamMembersModel } from "../model/team_member.model"
import { PageModel } from "./../model/page.model"
import { NeedsModel} from "./../model/needs.model"
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': ['application/json'],
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};
const httpUploadOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root'
})
export class NeedsService {
  constructor(
    private httpClient: HttpClient
  ) { }

 
  submit_concern(data: NeedsModel): Observable<[boolean, any]> {
    let needs_model: NeedsModel = {
        full_name: data.full_name== '' ? '-' : data.full_name,
        email: data.email=='' ? '-' : data.email,
        message: data.message==''? '-' : data.message,
        concern: data.concern==''? '-' : data.concern,
        company: data.company==''? '-' : data.company,
        contact_number: data.contact_number==''? '-' : data.contact_number,
        subject: data.subject==''? '-' : data.subject,
        captcha: data.captcha,
        category: data.category
      };
      return new Observable<[boolean, any]>((observer) => {
        this.httpClient.post(environment.NeedsAPIURL + '/needs', needs_model, httpOptions).subscribe(
          (response: any) => {
            let data = response.data;
            observer.next([true, data]);
            observer.complete();
          },
          error => {
            observer.next([false, error.error.message]);
            observer.complete();
          });
      });
  }
  
}
