<!-- <div class="container-fluid"> -->
  <div class="page-title m-b-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="'/games'">
                <svg class="stroke-icon">
                  <use href="assets/svg/icon-sprite.svg#stroke-home"></use>
                </svg>
              </a>
            </li>
            <li class="breadcrumb-item" *ngFor="let item of items">{{ item }}</li>
            <li class="breadcrumb-item active">{{ active_item }}</li>
          </ol>
        </div>
        <!-- <div class="col-6">
          <h3>{{ title }}</h3>
        </div> -->
        <!-- <div class="col-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="'/dashboard/default'">
                <svg class="stroke-icon">
                  <use href="assets/svg/icon-sprite.svg#stroke-home"></use>
                </svg>
              </a>
            </li>
            <li class="breadcrumb-item" *ngFor="let item of items">{{ item }}</li>
            <li class="breadcrumb-item active">{{ active_item }}</li>
          </ol>
        </div> -->
        <!-- <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/games'">
              <svg class="stroke-icon">
                <use href="assets/svg/icon-sprite.svg#stroke-home"></use>
              </svg>
            </a>
          </li>
          <li class="breadcrumb-item" *ngFor="let item of items">{{ item }}</li>
          <li class="breadcrumb-item active">{{ active_item }}</li>
        </ol> -->
      </div>
    </div>
  </div>
<!-- </div> -->
