import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { BlogModel } from 'src/app/models/blog/blog.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(
    private httpClient: HttpClient,
    private appSettings:AppSettings
    ) { }

  public options: {headers: HttpHeaders} = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
  };
  
  // get list of blog
  public getBlogPostList(language:string): Observable<any>{
    let selcted_language = language=='Chinese Simplified'? 'Chinese' : language
    return new Observable<any>((observer) => {
      this.httpClient.get(environment.BlogAPIURL + '/blogs/public?language='+selcted_language+'&feature=true&website=XGame', this.options).subscribe(
        response => {
          observer.next(response);
          observer.complete();
        }
      );
    });
  }

  // get list of blog
  public getBlogDetail(id: string,language:string): Observable<any>{
    let seelcted_language = language=='Chinese Simplified'? 'Chinese' : language
     return new Observable<any>((observer) => {
       this.httpClient.get(environment.BlogAPIURL + '/blogs/public/'+id+'?language='+seelcted_language+'&website=XGame', this.options).subscribe(
         response => {
           observer.next(response);
           observer.complete();
         }
       );
     });
   }
  // get list of blog
  public getFeaturedBlogPostList(language:string): Observable<any>{
    let selcted_language = language=='Chinese Simplified'? 'Chinese' : language
    return new Observable<any>((observer) => {
      this.httpClient.get(environment.BlogAPIURL + '/blogs/public/feature?language='+selcted_language+'&feature=true&website=XGame', this.options).subscribe(
        response => {
          observer.next(response);
          observer.complete();
        }
      );
    });
  }
}

