import { Injectable } from '@angular/core';
import { ContactModel } from './../model/contact.model';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }),
};

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private httpClient: HttpClient
  ) { }
  public add_needs(contact_model: ContactModel): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let url = environment.ContactAPIURL + '/needs';
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      };

      this.httpClient.post(url, contact_model, httpOptions).subscribe(
        response => {
          let data = response;
          observer.next([true, data]);
          observer.complete();
        },
        error => {
          observer.next([false, error.error.message]);
          observer.complete();
        });
    });
  }
}
