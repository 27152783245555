import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PageModel } from '../model/page.model';
import { TokenModel } from '../model/product.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': ['application/json'],
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private httpClient: HttpClient
  ) { }

  get_all_tokens(
    language:string,
    page_model: PageModel,
    keyword: string
    ): Observable<[boolean, any]> {
    let selected_language = language=='Chinese Simplified'? 'Chinese' : language
    return new Observable<[boolean, any]>((observer) => {
      let data: PageModel[] = [];
      this.httpClient
        .get<any>(environment.ProductAPIURL + '/token/public?language='+selected_language+'&page='+page_model.page+'&pageSize='+page_model.limit+'&keywords='+keyword, httpOptions)
        .subscribe(
          (response) => {
            let result = response;
            // let result_data = results['data'];
            if (result != null) {
              var data = result['data'];
              if (data.count > 0) {
                page_model = data;
              }
            }
            observer.next([true, page_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  public get_token_by_id(id: string,language:string): Observable<[boolean, any]> {
    let selected_language = language=='Chinese Simplified'? 'Chinese' : language

    return new Observable<[boolean, any]>((observer) => {
      let token_model: TokenModel;
      this.httpClient
        .get<any>(
          environment.ProductAPIURL + '/token/public/' + id +'?language='+selected_language,
          httpOptions
        )
        .subscribe(
          (response) => {
            let result = response;
            if (response != null) {
              token_model = {
                token_name: result['data'].token_name,
                token_logo: result['data'].token_logo,
                token_description: result['data'].token_translation,
                game: result['data'].game
              };
            }
            observer.next([true, token_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
}
