<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0 m-auto d-flex justify-content-between">
    <!-- <app-search></app-search> -->
    <div class="header-logo-wrapper col-auto p-0">
      <div class="logo-wrapper">
        <a routerLink="/">
          <img class="img-fluid for-light" src="assets/images/logo/logo.webp" alt="" />
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.webp" alt="" />
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
    <!-- <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0">
      <app-swiper></app-swiper>
    </div> -->
    <div class="nav-right col-8 p-0">
      <ul class="nav-menus">
        <li class="language-nav" (click)="languageToggle()">
          <app-languages></app-languages>
        </li>
        <!-- <li>
          <span class="header-search" (click)="searchToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#search"></use>
            </svg>
          </span>
        </li> -->
        <!-- <li class="onhover-dropdown">
          <app-bookmark></app-bookmark>
        </li> -->
        <li>
          <div class="mode" (click)="layoutToggle()">
            <svg style="width: 20px; height:20px">
              <use href="assets/svg/icon-sprite.svg#moon"></use>
            </svg>
          </div>
        </li>
        <!-- <li class="cart-nav onhover-dropdown">
          <app-cart></app-cart>
        </li> -->
        <!-- <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li> -->
        <li class="onhover-dropdown" >
          <app-network [wallet]="wallet"></app-network>
        </li>
        <li class="profile-nav onhover-dropdown p-0 d-flex flex-wrap align-content-center" *ngIf="wallet !=null" style="height: 33px;">
          <app-my-account></app-my-account>
        </li>
        <li *ngIf="wallet == null" style="padding: 0px 0px 0px 6px;">
          <app-connect-wallet></app-connect-wallet>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
