import { Injectable } from '@angular/core';
import { DappExtensionModel, WalletAccountsModel } from './../model/polkadot.model';
import { web3Accounts, web3AccountsSubscribe, web3Enable, web3FromAddress, web3FromSource } from '@polkadot/extension-dapp';
import { cryptoWaitReady, decodeAddress, signatureVerify } from '@polkadot/util-crypto';
import { mnemonicGenerate, mnemonicToMiniSecret, mnemonicValidate, ed25519PairFromSeed } from '@polkadot/util-crypto';

import { hexToU8a, isHex, stringToHex, stringToU8a, u8aToHex } from '@polkadot/util';
import { Keyring, encodeAddress } from '@polkadot/keyring';
import keyring from '@polkadot/ui-keyring';

import { ApiPromise } from '@polkadot/api';
import { WsProvider } from '@polkadot/rpc-provider';
// import { ContractPromise } from '@polkadot/api-contract';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from 'src/app/app-settings';
import { CookiesService } from './../services/cookies.service';
import { formatBalance, BN } from '@polkadot/util';
import { ContractPromise } from '@polkadot/api-contract';
import { NFTModel } from './../model/nft.model';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NetworkService } from './network.service';
import { NetworkModel } from '../model/network.model';
import { BaseDotsamaWallet, PolkadotjsWallet, TalismanWallet, Wallet, getWalletBySource,getWallets } from '@talismn/connect-wallets';

@Injectable({
  providedIn: 'root'
})
export class PolkadotService {
  // transactionFee = this.appSettings.transactionFee;
  private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  setCurrentBalance(data: any) {
    this.dataSubject.next(data);
  }

  getCurrentBalance() {
    return this.dataSubject.asObservable();
  }

  constructor(
    // private appSettings: AppSettings,
    private httpClient: HttpClient,
    private cookiesService: CookiesService,
    private networkService: NetworkService
  ) {
    this.getChainTokens();
    this.contractAddress = this.cookiesService.getCookie('contract_address');
    this.getNetworkDefault();
  }
  wsProvider = new WsProvider(this.cookiesService.getCookieArray('network')!=undefined? this.cookiesService.getCookieArray('network').wsProviderEndpoint  :environment.network[0].networks[0].wsProviderEndpoint);
  contractAddress;
  api = ApiPromise.create({ provider: this.wsProvider });
  keypair = environment.keypair;
  // extensions = web3Enable('XGAME DASHBOARD');
  // accounts = web3Accounts();
  abi = require("./../../../assets/json/sample.json");
  walletAccounts: WalletAccountsModel[] = [];

  getAbi(): Observable<any> {
    return this.httpClient.get<any>(this.abi);
  }

  getNetworkDefault(): void {
    this.networkService.get_network().subscribe((networks: NetworkModel[]) => {
      // Assuming you have a default network or need to find the default network
      const defaultNetwork = networks.find(network => network.is_default);

      if (defaultNetwork) {
        return defaultNetwork;
        // Use defaultNetwork as needed
      } else {
        console.error('Default network not found.');
      }
    }, error => {
      console.error('Error fetching networks:', error);
    });
  }
  
  async getAllSmartContracts() {
    let api = await this.api;
    const allContracts = await api.query.contracts.contractInfoOf.entries();

    // Extract contract addresses from the result
    const contractAddresses = allContracts.map(([key, _]) => key.args[0].toJSON());
    this.cookiesService.setCookie('smart_contract',contractAddresses[0]);
  }

  async getContract(api: any, abi: any, contractAddress: any) {
    try {
      const contract = new ContractPromise(api, abi, contractAddress);
      return contract;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  // async getAccount(contractAddress: any) {
  //   try {
  //     let accounts = [];

  //     do {
  //       await web3Enable('XGAME DASHBOARD');
  //       accounts = await web3Accounts();
  //       if (accounts.length === 0) {
  //         await new Promise(resolve => setTimeout(resolve, 1000));
  //       }
  //     } while (accounts.length === 0);
  //     const SENDER = this.cookiesService.getCookieArray("wallet-info").address;
  //     const injector = await web3FromAddress(SENDER);
  //     let api = await this.api;
  //     const contract = await this.getContract(api, this.abi, contractAddress);

  //     // Returns the data
  //     return { api, SENDER, injector, contract };
  //   } catch (error) {
  //     console.error('Get account error: ' + error);
  //     return undefined;
  //   }
  // }
  async getAccount(contractAddress: any) {
    try {
      const walletAddress = this.cookiesService.getCookieArray("wallet-info").address;
      let api = await this.api;
      const contract = await this.getContract(api, this.abi, contractAddress);
  
      // Returns the data
      return { api, SENDER: walletAddress, contract };
    } catch (error) {
      console.error('Get account error: ' + error);
      return undefined;
    }
  }
  

  async getBalance() {
    try {
      const contractAddress = await this.getAllSmartContracts();
      const accountData = await this.getAccount(contractAddress);

      if (accountData && accountData.api) {
        const { api, SENDER, contract } = accountData;
        const accountInfo: any = await api.query.system.account(SENDER);
        const { nonce, data: balance } = accountInfo.toJSON();
        const chainDecimals = api.registry.chainDecimals[0];
        formatBalance.setDefaults({ decimals: chainDecimals, unit: 'NMS' });
        formatBalance.getDefaults();
        const free = formatBalance(balance.free, { forceUnit: "NMS", withUnit: false });
        const balances = free.split(',').join('');
        this.setCurrentBalance(balances);
        return balances;
      } else {
        console.error('API not available in the returned data.');
        return undefined;
      }
    } catch (error) {
      console.error('Get account balance error:', error);
      return undefined;
    }
  }




  async getWeb3Accounts(): Promise<WalletAccountsModel[]> {
    let walletAccounts: WalletAccountsModel[] = [];
    let extension = web3Enable('XGAME DASHBOARD');
    let account = web3Accounts();
    if ((await extension).length > 0) {
      const accounts = await account;
      if (accounts.length > 0) {
        for (let i = 0; i < accounts.length; i++) {
          walletAccounts.push({
            address: accounts[i].address,
            address_display:  accounts[i].address.substring(0, 5) + "..." + accounts[i].address.substring(accounts[i].address.length - 5, accounts[i].address.length),
            metaGenesisHash: accounts[i].meta.genesisHash,
            metaName: accounts[i].meta.name,
            tokenSymbol: "",
            metaSource: accounts[i].meta.source,
            type: accounts[i].type
          });
        }
      }
    }

    return walletAccounts;
  }
  // async signAndVerify(walletAccount: WalletAccountsModel): Promise<boolean> {
  //   const injector = await web3FromSource(String(walletAccount.metaSource));
  //   const signRaw = injector?.signer?.signRaw;

  //   if (!!signRaw) {
  //     await cryptoWaitReady();

  //     const message: string = 'Please sign before you proceed. Thank you!';
  //     const { signature } = await signRaw({
  //       address: walletAccount.address,
  //       data: stringToHex(message),
  //       type: 'bytes'
  //     });

  //     let publicKey = decodeAddress(walletAccount.address);
  //     let hexPublicKey = u8aToHex(publicKey);

  //     let { isValid } = signatureVerify(message, signature, hexPublicKey);
  //     return isValid;
  //   }

  //   return false;
  // }

  async generateKeypair(address: string): Promise<string> {
    const keyring = new Keyring({ type: 'sr25519', ss58Format: 0 });
    const hexPair = keyring.addFromAddress(address);

    return hexPair.address;
  }

  async get_all_nfts(): Promise<NFTModel[]> {

    return new Promise<NFTModel[]>(async (resolve, reject) => {

      const contractCookie = this.cookiesService.getCookie('smart_contract');

      this.api.then(async (api) => {

        const contract = await this.getContract(api, this.abi, contractCookie);

        const gasLimit = api.registry.createType(
          'WeightV2',
          api.consts.system.blockWeights['maxBlock']
        );

        if (!contract) {
          reject(new Error('Contract not initialized.'));
          return;
        }

        if (!contract.query || !contract.query['getAllTokens']) {
          reject(new Error('getAllTokens function not found in the contract ABI.'));
          return;
        }

        if (contractCookie !== null) {

          // let nfts = contract.query['getAllTokens'];
          // console.log(nfts)
          contract.query['getAllTokens'](contractCookie, { gasLimit: gasLimit })
            .then(({ output }) => {
              const toks: any = output?.toJSON();

              if (toks.ok.length !== 0) {
                const nftModel: NFTModel[] = [];

                for (const tokenData of toks.ok) {
                  const token: NFTModel = {
                    nftTokenId: tokenData.nftTokenId,
                    imagePath: tokenData.imagePath,
                    name: tokenData.name,
                    description: tokenData.description,
                    price: tokenData.price,
                    isForSale: tokenData.isForSale,
                    isEquipped: tokenData.isEquipped,
                    category: tokenData.category,
                    collection: tokenData.collection,
                    astroType: tokenData.astroType,
                    rarity: tokenData.rarity,
                    network: tokenData.network,
                    blockchainId: tokenData.blockchainId,
                    collectionId: tokenData.collectionId,
                    tokenOwner: tokenData.tokenOwner,
                  };
                  nftModel.push(token);
                }

                resolve(nftModel);
              } else {
                resolve([]);
              }
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject(new Error('Contract cookie not found'));
        }
      });
    });
  }

  isAddressValid(walletAddress: string) {
    try {
      encodeAddress(
        isHex(walletAddress)
          ? hexToU8a(walletAddress)
          : decodeAddress(walletAddress)
      );
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getChainDecimals(amount: number) {
    let api = await this.api;
    const factor = new BN(10).pow(new BN(api.registry.chainDecimals));
    const convertedAmount = new BN(amount).mul(factor);
    return convertedAmount;
  }

  async checkBalance(wallet_address) {
    let api = await this.api;
    const balance = await api.derive.balances.all(wallet_address);
    const available = balance.availableBalance;
    const chainDecimals = api.registry.chainDecimals[0];
    formatBalance.setDefaults({ decimals: chainDecimals, unit: 'NMS' });
    formatBalance.getDefaults();
    const free = formatBalance(available, { forceUnit: "NMS", withUnit: false });
    const balances = free.split(',').join('');
    return parseFloat(balances) < 100 ? true : false;
  }

  async getChainTokens(): Promise<string> {
    const api = await this.api;
    const tokens = api.registry.chainTokens;
    this.cookiesService.setCookie('tokenSymbol', tokens[0]);
    return tokens[0];
  }
  // async getHashtransferBalance(fee: number) {
  //   try {
  //     let api = await this.api;
  //     let wallet = this.cookiesService.getCookie('wallet-keypair');
  //     const injector = await web3FromAddress(wallet);
  //     const signer = injector.signer;
  //     const balance = await api.derive.balances.all(wallet);
  //     const available = balance.availableBalance;
  //     const factor = new BN(10).pow(new BN(api.registry.chainDecimals));
  //     const amount = new BN(fee).mul(factor);
  //     const tfee = new BN(this.transactionFee).mul(factor);
  //     if(this.contractAddress == undefined || this.contractAddress == '') {
  //       this.contractAddress = this.appSettings.contractAddress;
  //     }
  //     const transfer = api.tx.balances.transfer(this.contractAddress, amount); // to get payment info
  //     const { partialFee } = await transfer.paymentInfo(wallet);
  //     const fees = partialFee.muln(110).divn(100);
  //     const total = amount
  //       .add(fees)
  //       .add(tfee)
  //       .add(api.consts.balances.existentialDeposit);
  //     const tx = api.tx.balances.transfer(this.contractAddress, total); // send with total payments
  //     const result = await tx.signAsync(wallet, { signer });
  //     if (total.gt(available)) {
  //       return console.error(`Cannot transfer ${amount} with ${available} left` );
  //     } else {
  //       let data: any;
  //       return data = {
  //         result: result,
  //         price: total.toNumber(),
  //         amount: amount.toNumber()
  //       };
  //     }
  //   } catch (error) {
  //     return false;
  //   }
  // }
  async transferBalanceReady(data: any) {
    let api = await this.api;
    let hash = data;
    const hashresult = await api.rpc.author.submitExtrinsic(hash);
  }
  async getBuyNftFees(recepient: string, nft: any) {
    let api = await this.api;
    let wallet = this.cookiesService.getCookie('wallet-keypair');
    const factor = new BN(10).pow(new BN(api.registry.chainDecimals));
    const amount = new BN(nft.price).mul(factor);
    const transfer = api.tx.balances.transfer(recepient, amount); // to get payment info
    const { partialFee } = await transfer.paymentInfo(wallet);
    const chainDecimals = api.registry.chainDecimals[0];
    formatBalance.setDefaults({ decimals: chainDecimals, unit: 'GNT' });
    formatBalance.getDefaults();
    const fees = partialFee.muln(110).divn(100).add(api.consts.balances.existentialDeposit);
    const free = formatBalance(fees, { forceUnit: "GNT", withUnit: false });
    const balances = free.split(',').join('');
    return balances;
  }
  generateMnemonic(): string {
    // Create mnemonic string for Alice using BIP39
    const mnemonicAlice = mnemonicGenerate();
      // Validate the mnemonic string that was generated
    const isValidMnemonic = mnemonicValidate(mnemonicAlice);
    if(mnemonicValidate(mnemonicAlice)){
      return mnemonicAlice
    }else{
      const mnemonicAlice = mnemonicGenerate();
      mnemonicValidate(mnemonicAlice)? mnemonicAlice: false;
    }
  }
  
  // createPolkadotWallet(mnemonic: string, password: string,name: string): Promise<any> {
  //   try {
  //       // Validate the mnemonic
  //       if (!mnemonicValidate(mnemonic)) {
  //           throw new Error('Invalid mnemonic');
  //       }

  //       // Create a new keyring instance
  //       const keyring = new Keyring({ type: 'sr25519' });

  //       // Add an account using the provided mnemonic and password
  //       const account = keyring.addFromUri(mnemonic, { name: name, password });


  //       // Return the wallet address
  //       return Promise.resolve(account.address);
  //   } catch (error) {
  //       console.error('Error creating Polkadot wallet:', error);
  //       throw error;
  //   }
  // }

  async createPolkadotWallet(mnemonic: string, password: string,name: string): Promise<any> {
    try {
        // Validate the mnemonic
        if (!mnemonicValidate(mnemonic)) {
            throw new Error('Invalid mnemonic');
        }
        cryptoWaitReady().then(() => {
          // load all available addresses and accounts
          keyring.loadAll({ ss58Format: 42, type: 'sr25519' });
          // add the account, encrypt the stored JSON with an account-specific password
          const { pair, json } = keyring.addUri(mnemonic, password, { name: name });

          const addresses = keyring.getAddresses();
          console.log(addresses);
          // additional initialization here, including rendering
        });
        
      
    } catch (error) {
        console.error('Error creating Polkadot wallet:', error);
        throw error;
    }
  }
  
  // async getDappExtension(): Promise<DappExtensionModel[]> {
  //   let web3WalletArray: DappExtensionModel[] = [];
  //   let extensions = await web3Enable('Talisman');
  //   if (extensions.length != 0) {
  //     await extensions.forEach(async data => {

  //       let walletAccounts: WalletAccountsModel[] = [];

  //       let accounts = await data.accounts.get();
        
  //       accounts.forEach(account => {
  //         walletAccounts.push({
  //           address: account.address,
  //           address_display: account.address.substring(0, 5) + "..." + account.address.substring(account.address.length - 5, account.address.length),
  //           metaGenesisHash: account.genesisHash,
  //           metaName: account.name,
  //           tokenSymbol: "",
  //           metaSource: data.name,
  //           type: account.type
  //         });
  //       });
        
  //       web3WalletArray.push({
  //         name: data.name,
  //         WalletAccounts: walletAccounts
  //       });
  //     })
  //     return web3WalletArray;
  //   }

  //   return [];
  // }

  getAllExtension(): any{
    // get an array of wallets which are installed
    // const supportedWallets = getWallets().filter(wallet => wallet.installed)
    const supportedWallets: Wallet[] = getWallets();
    return supportedWallets;
  }

  connectExtension(extension: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let wallet: TalismanWallet | PolkadotjsWallet | null = null;
      this.walletAccounts = [];
      
      if (extension === 'talisman') {
        wallet = getWalletBySource('talisman') as TalismanWallet;
      } else if (extension === 'polkadot-js') {
        wallet = getWalletBySource('polkadot-js') as PolkadotjsWallet;
      }
  
      if (wallet) {
        wallet.enable('XGame')
          .then(() => {
            wallet.subscribeAccounts((accounts) => {
              accounts.forEach((account) => {
                this.walletAccounts.push({
                  address: account.address,
                  address_display: account.address.substring(0, 5) + "..." + account.address.substring(account.address.length - 5),
                  metaGenesisHash: '',
                  metaName: account.name,
                  tokenSymbol: "",
                  metaSource: account.wallet.extensionName,
                  type: ''
                });
              });
              resolve(this.walletAccounts);
            }).catch((error) => {
                reject(error);
            });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error('Wallet not found'));
      }
    });
  }

  async signAndVerify(walletAccount: WalletAccountsModel): Promise<any> {
    try {
      const wallet = getWalletBySource(walletAccount.metaSource);
      const accounts = await wallet.getAccounts();
      const currentAccount = accounts.find((acc) => acc.address === walletAccount.address);
  
      if (!currentAccount) {
        console.error('Account not found.');
        return; // or handle accordingly
      }
  
      const signer = currentAccount.wallet.signer;
      const message: string = 'Please sign before you proceed. Thank you!';
      const { signature } = await signer.signRaw({
        type: 'bytes',
        data: stringToHex(message),
        address: currentAccount.address,
      });
  
      let publicKey = decodeAddress(walletAccount.address);
      let hexPublicKey = u8aToHex(publicKey);
  
      let { isValid } = signatureVerify(message, signature, hexPublicKey);
      return isValid;
    } catch (err) {
      console.error('Error:', err);
      // Handle error...
    }
  }
  
  
}
