import { Component, AfterViewInit, ElementRef, OnInit, ViewChild,Inject,NgZone, PLATFORM_ID } from '@angular/core';

import * as am5 from '@amcharts/amcharts5';
import DarkTheme from '@amcharts/amcharts5/themes/Dark';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5stock from '@amcharts/amcharts5/stock';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Micro from "@amcharts/amcharts5/themes/Micro";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";

import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-stock-chart',
  templateUrl: './stock-chart.component.html',
  styleUrls: ['./stock-chart.component.scss']
})
export class StockChartComponent implements AfterViewInit{
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone,
  ){

  }
  private root!: am5.Root;
  private chartData: any = [

  ];

  ngAfterViewInit() {
    this.root = am5.Root.new("chartdiv");
    this.chartTheme();
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  chartTheme(){
    this.root.setThemes([
      DarkTheme.new(this.root),
    ]);
    this.chart();
  }

  chart(){
    // let root = am5.Root.new("chartdiv");
    const myTheme = am5.Theme.new(this.root);
    myTheme.rule("Grid", ["scrollbar", "minor"]).setAll({
      visible:false
    });

    // myTheme.rule("Label").setAll({
    //   fill: am5.color(0xFF0000),
    // });
    this.root.setThemes([
      // am5themes_Dark.new(this.root),
      am5xy.DefaultTheme.new(this.root),
      myTheme
    ]);

    // Create a stock chart
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/#Instantiating_the_chart
    let stockChart = this.root.container.children.push(am5stock.StockChart.new(this.root, {
      paddingRight: 0
    }));

    // Set global number format
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/concepts/formatters/formatting-numbers/
    this.root.numberFormatter.set("numberFormat", "#,###.00");

    // Create a main stock panel (chart)
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/#Adding_panels
    let mainPanel = stockChart.panels.push(am5stock.StockPanel.new(this.root, {
      wheelY: "zoomX",
      panX: true,
      panY: true
    }));

    // Create value axis
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let valueAxis = mainPanel.yAxes.push(am5xy.ValueAxis.new(this.root, {
      renderer: am5xy.AxisRendererY.new(this.root, {
        pan: "zoom"
      }),
      extraMin: 0.1, // adds some space for for main series
      tooltip: am5.Tooltip.new(this.root, {}),
      numberFormat: "#,###.00",
      extraTooltipPrecision: 2
    }));

    let dateAxis = mainPanel.xAxes.push(am5xy.GaplessDateAxis.new(this.root, {
      baseInterval: {
        timeUnit: "day",
        count: 1
      },
      renderer: am5xy.AxisRendererX.new(this.root, {
        minorGridEnabled: true
      }),
      tooltip: am5.Tooltip.new(this.root, {})
    }));

    // Add series
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let valueSeries = mainPanel.series.push(am5xy.CandlestickSeries.new(this.root, {
      name: "XGM",
      clustered: false,
      valueXField: "Date",
      valueYField: "Close",
      highValueYField: "High",
      lowValueYField: "Low",
      openValueYField: "Open",
      calculateAggregates: true,
      xAxis: dateAxis,
      yAxis: valueAxis,
      legendValueText: "open: [bold]{openValueY}[/] high: [bold]{highValueY}[/] low: [bold]{lowValueY}[/] close: [bold]{valueY}[/]",
      legendRangeValueText: ""
    }));

    // Set main value series
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/#Setting_main_series
    stockChart.set("stockSeries", valueSeries);

    // Add a stock legend
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/stock-legend/
    let valueLegend = mainPanel.plotContainer.children.push(am5stock.StockLegend.new(this.root, {
      stockChart: stockChart
    }));


    // Create volume axis
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let volumeAxisRenderer = am5xy.AxisRendererY.new(this.root, {
      inside: true,
      pan: "zoom"
    });

    volumeAxisRenderer.labels.template.set("forceHidden", true);
    volumeAxisRenderer.grid.template.set("forceHidden", true);

    let volumeValueAxis = mainPanel.yAxes.push(am5xy.ValueAxis.new(this.root, {
      numberFormat: "#.#a",
      height: am5.percent(20),
      y: am5.percent(100),
      centerY: am5.percent(100),
      renderer: volumeAxisRenderer
    }));

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let volumeSeries = mainPanel.series.push(am5xy.ColumnSeries.new(this.root, {
      name: "Volume",
      clustered: false,
      valueXField: "Date",
      valueYField: "Volume",
      xAxis: dateAxis,
      yAxis: volumeValueAxis,
      legendValueText: "[bold]{valueY.formatNumber('#,###.0a')}[/]"
    }));

    volumeSeries.columns.template.setAll({
      strokeOpacity: 0,
      fillOpacity: 0.5
    });

    // color columns by stock rules
    volumeSeries.columns.template.adapters.add("fill", function (fill, target) {
      let dataItem = target.dataItem;
      if (dataItem) {
        return stockChart.getVolumeColor(dataItem);
      }
      return fill;
    })

    // Set main series
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/#Setting_main_series
    stockChart.set("volumeSeries", volumeSeries);
    valueLegend.data.setAll([valueSeries, volumeSeries]);

    // Add cursor(s)
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    mainPanel.set("cursor", am5xy.XYCursor.new(this.root, {
      yAxis: valueAxis,
      xAxis: dateAxis,
      snapToSeries: [valueSeries],
      snapToSeriesBy: "y!"
    }));
    // Add scrollbar
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    let scrollbar = mainPanel.set("scrollbarX", am5xy.XYChartScrollbar.new(this.root, {
      orientation: "horizontal",
      height: 50
    }));
    stockChart.toolsContainer.children.push(scrollbar);

    let sbDateAxis = scrollbar.chart.xAxes.push(am5xy.GaplessDateAxis.new(this.root, {
      baseInterval: {
        timeUnit: "day",
        count: 1
      },
      renderer: am5xy.AxisRendererX.new(this.root, {
        minorGridEnabled: true
      })
    }));

    let sbValueAxis = scrollbar.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
      renderer: am5xy.AxisRendererY.new(this.root, {})
    }));

    let sbSeries = scrollbar.chart.series.push(am5xy.LineSeries.new(this.root, {
      valueYField: "Close",
      valueXField: "Date",
      xAxis: sbDateAxis,
      yAxis: sbValueAxis
    }));

    sbSeries.fills.template.setAll({
      visible: true,
      fillOpacity: 0.3
    });

    this.chartData = [];


    // Stock toolbar
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/toolbar/
    let toolbar = am5stock.StockToolbar.new(this.root, {
      container: document.getElementById("chartcontrols"),
      stockChart: stockChart,
      controls: [
        am5stock.IndicatorControl.new(this.root, {
          stockChart: stockChart,
          legend: valueLegend
        }),
        am5stock.DateRangeSelector.new(this.root, {
          stockChart: stockChart
        }),
        am5stock.PeriodSelector.new(this.root, {
          stockChart: stockChart
        }),

        am5stock.DrawingControl.new(this.root, {
          stockChart: stockChart
        }),
        am5stock.ResetControl.new(this.root, {
          stockChart: stockChart
        }),
        am5stock.SettingsControl.new(this.root, {
          stockChart: stockChart
        })
      ]
    })
    // set data to all series
    valueSeries.data.setAll(this.chartData);
    volumeSeries.data.setAll(this.chartData);
    sbSeries.data.setAll(this.chartData);
  }

}
