import { Component, Input } from "@angular/core";
import { ChartOptions } from "../../data/chart/apex";

@Component({
  selector: "app-coin",
  templateUrl: "./coin.component.html",
  styleUrls: ["./coin.component.scss"],
})
export class CoinComponent {
  @Input() data: any;

  constructor(){
  }
  primary_color = localStorage.getItem('primary_color') || '#7366ff';
  secondary_color = localStorage.getItem('secondary_color') || '#f73164';

  splineArea2: ChartOptions = {
    series: [
        {
            name: "STOCK ABC",
            data: [8107.85, 8128.0, 8122.9, 8165.5, 8340.7, 8423.7, 8423.5, 8514.3, 8481.85, 8487.7, 8506.9, 8626.2, 8668.95, 8602.3, 8607.55, 8512.9, 8496.25, 8600.65, 8881.1, 9340.85],
        }
    ],
    colors: [this.primary_color],
    chart: {
        type: "area",
        height: 350,
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: "straight"
    },
    labels: ["13 Nov 2022", "14 Nov 2022", "15 Nov 2022", "16 Nov 2022", "17 Nov 2022", "20 Nov 2022", "21 Nov 2022", "22 Nov 2022", "23 Nov 2022", "24 Nov 2022", "27 Nov 2022", "28 Nov 2022", "29 Nov 2022", "30 Nov 2022", "01 Dec 2022", "04 Dec 2022", "05 Dec 2022", "06 Dec 2022", "07 Dec 2022", "08 Dec 2022"
    ],
    xaxis: {
        type: "datetime"
    },
    yaxis: {
        opposite: true
    },
    legend: {
        horizontalAlign: "left"
    }
};
}
