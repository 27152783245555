import { Injectable, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GenreModel, ProductModel } from './../model/product.model';
import { AppSettings } from '../../app-settings';
import { environment } from 'src/environments/environment';
import { PageModel } from '../model/page.model';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': ['application/json'],
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};
const httpUploadOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private appSettings: AppSettings,
    private httpClient: HttpClient
  ) { }
  

  get_all_products(
    language:string,
    page_model: PageModel,
    ): Observable<[boolean, any]> {
    let selected_language = language=='Chinese Simplified'? 'Chinese' : language
    return new Observable<[boolean, any]>((observer) => {
      let data: PageModel[] = [];
      this.httpClient
        .get<any>(environment.ProductAPIURL + 'games/public?language='+selected_language+'&page='+page_model.page+'&pageSize='+page_model.limit, httpOptions)
        .subscribe(
          (response) => {
            let result = response;
            // let result_data = results['data'];
            if (result != null) {
              var data = result['data'];
              if (data.count > 0) {
                page_model = data;
              }
            }
            observer.next([true, page_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  get_all_product_list(
    language:string,
    page_model: PageModel,
    selectedGenres: any,
    keywords: string,
    upcoming: boolean,
    release: boolean
    ): Observable<[boolean, any]> {
    let selected_language = language=='Chinese Simplified'? 'Chinese' : language
    return new Observable<[boolean, any]>((observer) => {
      let page_data: PageModel = new PageModel();
      this.httpClient
        .get<any>(environment.ProductAPIURL + '/games/public/list?language='+selected_language
        +'&page='+page_model.page
        +'&pageSize='+page_model.limit
        +'&keywords='+keywords
        +'&selected_genre='+selectedGenres
        +'&upcoming='+upcoming
        +'&release='+release
        , httpOptions)
        .subscribe(
          (response) => {
            page_data = new PageModel();
            let result = response;
            // let result_data = results['data'];
            if (result != null) {
              var data = result['data'];
              if (data.count > 0) {
                page_data = data;
              }
            }
            observer.next([true, page_data]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }

  public get_product_by_id(id: string,language:string): Observable<[boolean, any]> {
    let selected_language = language=='Chinese Simplified'? 'Chinese' : language

    return new Observable<[boolean, any]>((observer) => {
      let game_model: ProductModel;
      this.httpClient
        .get<any>(
          environment.ProductAPIURL + '/games/public/' + id +'?language='+selected_language,
          httpOptions
        )
        .subscribe(
          (response) => {
            let result = response;
            if (response != null) {
              game_model = {
                id: result['data'].id,
                game_banner: result['data'].game_banner,
                game_logo: result['data'].game_logo,
                game_name: result['data'].game_name,
                game_link: result['data'].game_link,
                upcoming: result['data'].upcoming,
                game_description: result['data'].game_description,
                trailer_link: result['data'].trailer_link,
                ingame_images: result['data'].ingame_images!='-'?JSON.parse(result['data'].ingame_images):'',
                token: result['data'].token,
                developer: result['data'].developer,
                governance: result['data'].governance,
                onboarding: result['data'].onboarding,
                language: result['data'].language==''|| result['data'].language=='-'? result['data'].language : JSON.parse(result['data'].language) ,
                release_date: result['data'].release_date,
                game_genre: result['data'].game_genre,
                system_requirements: result['data'].system_requirements,
                nft_api: result['data'].nft_api,
                nft_banner: result['data'].nft_banner
              };
            }
            observer.next([true, game_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  get_all_genre_dropdown(): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let genre_list: GenreModel[] = [];
      this.httpClient
        .get<any>(environment.ProductAPIURL + '/genre/public', httpOptions)
        .subscribe(
          (response) => {
            let result = response;
            let result_data = result['data'];
            if (result_data != null) {
              genre_list = result_data
            }
            observer.next([true, genre_list]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }

  get_all_product_nft_drop(
    language:string,
    page_model: PageModel,
    keywords: string,
    ): Observable<[boolean, any]> {
    let selected_language = language=='Chinese Simplified'? 'Chinese' : language
    return new Observable<[boolean, any]>((observer) => {
      let page_data: PageModel = new PageModel();
      this.httpClient
        .get<any>(environment.ProductAPIURL + '/games/public/drops?language='+selected_language
        +'&page='+page_model.page
        +'&pageSize='+page_model.limit
        +'&keywords='+keywords
        , httpOptions)
        .subscribe(
          (response) => {
            page_data = new PageModel();
            let result = response;
            // let result_data = results['data'];
            if (result != null) {
              var data = result['data'];
              if (data.count > 0) {
                page_data = data;
              }
            }
            observer.next([true, page_data]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  get_category_json(): Observable<any> {
    return this.httpClient.get<any>("./../../../assets/json/category.json");
  }
  get_collection_json(): Observable<any> {
    return this.httpClient.get<any>("./../../../assets/json/collection.json");
  }
}