import { Component } from '@angular/core';

@Component({
  selector: 'app-identicon',
  templateUrl: './identicon.component.html',
  styleUrls: ['./identicon.component.scss']
})
export class IdenticonComponent {

}
