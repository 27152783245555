import { Routes } from '@angular/router';

export const content: Routes = [
  // {
  //   path: "marketplace",
  //   loadChildren: () => import("../../components/apps/e-commerce/e-commerce.module").then((m) => m.ECommerceModule),
  // },
  // {
  //   path: 'home',
  //   loadChildren: () =>
  //     import('../../components/apps/homepage/home.module').then(
  //       (m) => m.HomeModule
  //     ),
  // },
  {
    path: 'blogs',
    loadChildren: () =>
      import('../../components/apps/blogpage/blog.module').then(
        (m) => m.BlogModule
      ),
  },
  {
    path: 'games',
    loadChildren: () =>
      import('../../components/apps/gamepage/game.module').then(
        (m) => m.GameModule
      ),
  },
  {
    path: 'nft',
    loadChildren: () =>
      import('../../components/apps/nftpage/nft.module').then(
        (m) => m.NFTModule
      ),
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('../../components/apps/aboutuspage/about-us.module').then(
        (m) => m.AboutUsModule
      ),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('../../components/apps/contactuspage/contact-us.module').then(
        (m) => m.ContactUsModule
      ),
  },
  // {
  //   path: 'tokenomics',
  //   loadChildren: () =>
  //     import('../../components/apps/tokenomicspage/tokenomics.module').then(
  //       (m) => m.TokenomicsModule
  //     ),
  // },
  // {
  //   path: 'web3',
  //   loadChildren: () =>
  //     import('../../components/apps/web3page/web3.module').then(
  //       (m) => m.web3Module
  //     ),
  // },
  // {
  //   path: 'wallet',
  //   loadChildren: () =>
  //     import('../../components/apps/walletpage/wallet.module').then(
  //       (m) => m.WalletModule
  //     ),
  // },
  // {
  //   path: 'gem',
  //   loadChildren: () =>
  //     import('../../components/apps/gempage/gem.module').then(
  //       (m) => m.GameModule
  //     ),
  // },
  // {
  //   path: 'game_info/:id',
  //   loadChildren: () =>
  //     import('../../components/apps/productpage/product.module').then(
  //       (m) => m.ProductModule
  //     ),
  // },
  {
    path: 'policy',
    loadChildren: () =>
      import('../../components/apps/policy-page/policy.module').then(
        (m) => m.PolicyModule
      ),
  },
  {
    path: 'teams',
    loadChildren: () =>
      import('../../components/apps/teampage/teampage.module').then(
        (m) => m.TeampageModule
      ),
  },
  {
    path: 'token',
    loadChildren: () =>
      import('../../components/apps/tokenpage/token.module').then(
        (m) => m.TokensModule
      ),
  },
];
