import { Injectable } from '@angular/core';
import { TeamMembersModel } from "../model/team_member.model"
import { PageModel } from "./../model/page.model"
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': ['application/json'],
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};
const httpUploadOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  page_model: PageModel = new PageModel();
  constructor(
    private httpClient: HttpClient
  ) { }

 
  get_all_teams(page_model: PageModel): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let teams_list: TeamMembersModel[] = [];
      this.httpClient
        .get<any>(environment.TeamsAPIURL + '/teams/public?page='+page_model.page+'&pageSize='+page_model.limit+'&language=English', httpOptions)
        .subscribe(
          (response) => {
            let result = response;
            let result_data = result['data'];
            if (result_data != null) {
              page_model = result_data
              // var data = result['data'];
              // if (data.length > 0) {
              //   for (let i = 0; i <= data.length - 1; i++) {
              //     teams_list.push({
              //       id: data[i].id,
              //       team_name: data[i].team_name,
              //       status: data[i].status,
              //     })
              //   }
              // }
            }
            observer.next([true, page_model]);
            observer.complete();
          },
          (error) => {
            observer.next([false, error.status]);
            observer.complete();
          }
        );
    });
  }
  
}
